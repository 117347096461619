.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7;
}

.contact-form {
  background: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
}

.contact-form h2 {
  text-align: center;
  color: #333;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.social-links a {
  color: #0077b5; /* LinkedIn color */
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
  color: #0056b3;
  transform: scale(1.1);
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  color: #666;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #3498db;
  outline: none;
}

.contact-form button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-form button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

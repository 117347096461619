/* src/styles/Experience.css */
.experience {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    padding: 20px;
    min-height: 100vh;
    color: #333;
  }
  
  .experience h2 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
  }

  .job-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .job {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .job h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #2c3e50;
    padding: auto;
  }
  
  .duration {
    font-size: 1rem;
    font-style: italic;
    color: #7f8c8d;
    margin-bottom: 15px;
  }
  
  ul {
    list-style-type: none;
    margin-left: 20px;
    padding-left: 0;
  }
  
  ul li {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .experience h2 {
      font-size: 2rem;
    }
  
    .job {
      padding: 10px;
      margin-bottom: 20px;
    }
  }
  
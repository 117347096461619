.home {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px); /* Adjust this value based on your header/footer height */
  padding: 20px; /* Keeps a space around the edges of the .home container */
  background-color: #f0f2f5;
}

.home-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto; /* Centers the content and adds space from the edges */
  padding: 0 20px; /* Adds horizontal padding to keep content from the edges */
}

.home-text {
  flex: 1;
  padding-right: 40px; /* Adjust this if needed for better spacing */
}

.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.home-image img:hover {
  transform: scale(1.05);
}

h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #333;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #666;
}

p {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #444;
  line-height: 1.6;
}

.highlight {
  color: #007bff;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn {
  display: inline-block;
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  margin: 0 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .home-content {
    flex-direction: column-reverse;
    text-align: center;
    padding: 20px; /* Ensures padding is consistent in smaller screens */
  }

  .home-text, .home-image {
    padding-right: 0;
    padding-top: 40px;
  }

  .home-image img {
    max-width: 250px;
  }

  .cta-buttons {
    justify-content: center;
  }
}

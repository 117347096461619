html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
}

header {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

header h1 {
  margin: 0;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  color: #ecf0f1;
  text-decoration: none;
}

main {
  flex: 1;
  padding: 20px;
  padding-top: 80px; /* To account for the fixed header */
  overflow-y: auto;
}

h2 {
  color: #2c3e50;
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 10px;
}

.job, .project {
  margin-bottom: 30px;
}

.duration {
  font-style: italic;
  color: #7f8c8d;
}

ul {
  padding-left: 20px;
}

.skill-category {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

input, textarea {
  margin-bottom: 10px;
  padding: 10px;
}

button {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 10px;
  border: none;
  cursor: pointer;
}

footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1000;
}

.home .cta-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn {
  display: inline-block;
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  margin: 0 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #2980b9;
}

/* src/styles/About.css */
.about {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  padding: 20px;
  min-height: 100vh; /* Ensures the projects section takes full viewport height */
  display: flex; /* Aligns the content horizontally */
  flex-direction: column; /* Stacks the content vertically */
  color: #333; /* Dark color for better readability */
}

.about h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px; /* Space below the section heading */
  text-align: center; /* Centers the section title */
}

.about-content p {
  font-size: 1.1rem;
  margin-bottom: 20px; /* Provides consistent spacing between paragraphs */
  color: #444;
  line-height: 1.6;
  text-align: left; /* Centers the text for readability */
}

.resume-download {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  margin-top: 20px; /* Space above the button */
}

.btn {
  padding: 12px 24px;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .about h2 {
    font-size: 2rem;
  }

  .about-content {
    padding: 20px; /* Ensures padding is consistent on smaller screens */
  }
}

.styled-header {
    background-color: #2c3e50;
    padding: 1rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
  }
  
  .logo-image {
    height: 60px; /* Adjust size as needed */
    margin-right: 10px; /* Space between logo and name */
  }
  
  .logo-name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .nav-links {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    margin-right: 25px;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }
  
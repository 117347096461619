/* src/styles/Education.css */
#education {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    padding: 20px;
    min-height: 100vh;
    color: #333;
  }
  
  #education h2 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
  }

  .duration {
    font-size: 1rem;
    font-style: italic;
    color: #7f8c8d;
    margin-bottom: 15px;
  }
  
  .university {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .undergrad {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .undergrad h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #2c3e50;
  }

  .grad {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

    .grad h3 {
        font-size: 1.8rem;
        margin-bottom: 10px;
        color: #2c3e50;
    }
  
  #education div {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  #education h3 {
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  #education p {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 10px;
  }

  .duration {
    font-size: 1rem;
    font-style: italic;
    color: #7f8c8d;
    margin-bottom: 15px;
  }
  
  ul {
    list-style-type: none;
    margin-left: 20px;
    padding-left: 0;
  }
  
  ul li {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    #education h2 {
      font-size: 2rem;
    }
    
    #education div {
      padding: 10px;
      margin-bottom: 20px;
    }
  }
  